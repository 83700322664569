import React, { useEffect } from 'react'
import Head from 'next/head'
import MainLayout from 'layouts/MainLayout/MainLayout'
import { Page } from 'models/page.interface'
import { getCustomServerSideProps } from 'utils/getCustomServerSideProps'
import faqData from '../data/homepage-faqs-data.json'
import APIDocumentation from 'components/Home/APIDocumentation/APIDocumentation'
import { CtaSection } from 'components/Home/CtaSection/CtaSection'
import { DomainBackorderingSection } from 'components/Home/DomainBackorderingSection/DomainBackorderingSection'
import { FeaturesSection } from 'components/Home/FeaturesSection/FeaturesSection'
import { ToolsSection } from 'components/Home/ToolsSection/ToolsSection'
import { Hero } from 'components/Home/Hero/Hero'
import FAQSection from 'components/common/sections/FAQSection/FAQSection'
import { StatsSection } from 'components/common/sections/StatsSection/StatsSection'
import CustomNextSeo from 'components/CustomNextSeo'
import { useRouter } from 'next/router'
import { localStorageService } from 'services/localStorage.service'
import { LocalStorageKeys } from 'enums/local-storage-keys'
import { useTrackAffiliateClick } from '../hooks/useTrackAffiliateClick'

interface Props {}

const Home: Page<Props> = ({}) => {
  const router = useRouter()

  const { mutateAsync: trackAffiliateClick } = useTrackAffiliateClick()
  const [tracked, setTracked] = React.useState(false)
  const urlInviteToken = router.query.invite_token?.toString()
  const urlInviteName = router.query.name?.toString()

  React.useEffect(() => {
    if (tracked) return;
    const params = localStorageService.get(LocalStorageKeys.referralParams)
    let parsedParams: any = {}

    try {
      parsedParams = JSON.parse(params) || {}
    } catch (e) {
      // noop
    }

    if (parsedParams?.inviteToken !== urlInviteToken) {
      trackAffiliateClick(urlInviteToken).catch(() => {
        // noop
      })
      setTracked(true)
    }
  }, [urlInviteToken, tracked, trackAffiliateClick]);

  useEffect(() => {
    if (urlInviteToken || urlInviteName) {
      localStorageService.set(
        LocalStorageKeys.referralParams,
        JSON.stringify({
          inviteToken: urlInviteToken,
          inviteName: urlInviteName,
        })
      )
    }
  }, [urlInviteToken, urlInviteName])

  return (
    <>
      <Head>
        {/* JSON-LD for the search box */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://bishopi.io/",
              "description": "Bishopi is the #1 AI-powered domain name tool platform. We simplify buying and selling domains by providing valuable insights, comprehensive information, pre-purchase and back-order options, alongside detailed brand statistics and SEO metrics.",
            }),
          }}
        />
        {/* JSON-LD for breadcrumbs */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "description": "",
              "email": "info(at)scrapingbee.com",
              "member": [
                {
                    "@type": "Organization"
                },
                {
                    "@type": "Organization"
                }
            ],
            "name": "Bishopi",
            }),
          }}
        />
      </Head>
      <CustomNextSeo
        title="#1 AI-Powered Domain Name Tools & Services"
        keywords="domain tools,domain valuation,domain value,domain name worth,domain worth"
        description="Bishopi is the #1 AI-powered domain name tool platform. We simplify buying and selling domains by providing valuable insights, comprehensive information, pre-purchase and back-order options, alongside detailed brand statistics and SEO metrics."
        canonical="https://bishopi.io/"
        ogType="website"
        ogTitle="#1 AI-Powered Domain Name Tools & Services"
        ogDescription="Bishopi is the #1 AI-powered domain name tool platform. We simplify buying and selling domains by providing valuable insights, comprehensive information, pre-purchase and back-order options, alongside detailed brand statistics and SEO metrics."
        ogUrl="https://bishopi.io/"
      />
      <Hero />
      <FeaturesSection />
      <ToolsSection />
      <DomainBackorderingSection />
      <APIDocumentation />
      <CtaSection />
      <FAQSection data={faqData} />
      <StatsSection />
    </>
  )
}

export const getServerSideProps = async (ctx) => {
  const { props: customProps } = await getCustomServerSideProps(ctx)

  return {
    props: {
      ...customProps,
    },
  }
}

Home.getLayout = (page: React.ReactElement) => <MainLayout>{page}</MainLayout>

export default Home
